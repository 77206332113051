import React, { FC, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getValidateAlertContactStatus } from "saur-states-management/lib/selectors/exchangeSelectors";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import { device } from "saur-design-sys-web/lib/Theme";
import { useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import ContentUnloggedWrapper from "../templates/ContentUnloggedWrapper";
import { geti18nContext } from "../common/translation";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import { exchangeAction } from "saur-states-management/lib/states/exchangeState";
import Loader from "saur-design-sys-web/lib/Atoms/Loader/Loader";
import RainIcon from "saur-design-sys-web/lib/Icons/RainIcon";
import WaterSavingBig from "saur-design-sys-web/lib/Icons/WaterSavingBig";
import ID_VALUES from "../automated-tests-ids/ids";

const AlertContactScreen: FC<{}> = () => {
  const translation = useContext(geti18nContext()); 
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const validationStatus = useSelector(getValidateAlertContactStatus);
  const [status, setStatus] = useState<null | string>(null);
  const [codeError, setCodeError] = useState<null | number>(null);
    
  useEffect(() => {
    if (token) {
      setTimeout(
        () => dispatch(exchangeAction.validateAlertContact(token)),
        100
      );
    }
  }, [token, dispatch]);

  useEffect(() => {
    setStatus(validationStatus.status);
    setCodeError(validationStatus.errorCode);
  }, [validationStatus]);

  const getValue = () => {
    const statusOfValidation = status === "success";
    const translations = translation?.exchanges.validateAlertContact;
    let title;
    let firstParagraph;
    let secondParagraph;
    let icon;

    if (statusOfValidation) {
      title = translations?.titleSuccess;
      firstParagraph = translations?.firstParagraphSuccess;
      secondParagraph = "";
      icon = <WaterSavingBig />;
    } else {
      if (codeError === 403) {
        title = translations?.alreadyAddedAsCoReceiver;
        firstParagraph = translations?.alertCoReceiver;
        secondParagraph = "";
        icon = <WaterSavingBig />;
      } else {
        title = translations?.titleFailure;
        firstParagraph = translations?.firstParagraphFailure;
        secondParagraph = translations?.secondParagraphFailure;
        icon = <RainIcon />;
      }
    }

    return {
      title,
      firstParagraph,
      secondParagraph,
      icon
    };
  };

  return (
    <>
      <Styles />
      <Container className="alert_contact_screen-container" automatedTestId={ID_VALUES.alertConactValidation}>
        <ContentUnloggedWrapper>
          <Container className="alert_contact_screen-row">
            <Container className="alert_contact_screen-content-container">
              {status ? (
                <Container className="alert_contact_screen-content">
                  <Word
                    value={getValue().title}
                    variant="h3"
                    textStyle="titleSmall"
                    className="alert_contact_screen-title"
                  />
                  <Container className="alert_contact_screen-icon">
                    {getValue().icon}
                  </Container>
                  <Word
                    value={getValue().firstParagraph}
                    variant="p"
                    textStyle="normalReg"
                    className="alert_contact_screen-first-paragraph"
                  />
                  {codeError !== 403 &&
                    <Word
                      value={getValue().secondParagraph}
                      variant="p"
                      textStyle="normalReg"
                      className="alert_contact_screen-second-paragraph"
                      automatedTestId={ID_VALUES.alertConactValidationSecondParagraphe}
                    />
                  }
                </Container>
              ) : (
                <Loader />
              )}
            </Container>
          </Container>
        </ContentUnloggedWrapper>
      </Container>
    </>
  );
};

const Styles = createGlobalStyle`
.sds-reset-style{
  .alert_contact_screen-container {
    position: relative;
    min-height: 100vh;

    .alert_contact_screen-row {          
      flex: 1;
      .alert_contact_screen-content-container {
        flex: 1;
        padding: 0 20px;
        align-items: center;
        @media ${device.mobile} {
          justify-content: flex-start;
          background: url('/assets/wave-mobile.svg');
          background-size: cover;
        }
        @media ${device.tablet} {
          border-top-right-radius: 0;
          justify-content: center;
          background: url('/assets/wave-desktop.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;          
        }  
        .alert_contact_screen-content {
            display: flex;
            align-items: center;
        .alert_contact_screen-title {
            margin-top: 20px;
            text-align: center;
        }
        .alert_contact_screen-first-paragraph, .alert_contact_screen-second-paragraph {
            text-align: center;
        }
        .alert_contact_screen-icon svg {
            margin-top: 28px;
            margin-bottom: 14px;
            width: 200px;
            height: 200px;
        
            @media ${device.tablet} {
              position: initial;
              display: initial;
            }
          }
        }
      }
    }
  }
}
`;

export default AlertContactScreen;
