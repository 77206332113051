/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import {
  onlyForDesktop,
  onlyForMobile,
  device,
  elevation,
} from "saur-design-sys-web/lib/Theme";
import { usePath } from "../common/navigation";
import { useHistory } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import BubbleHeader from "../templates/BubbleHeader";
import { langTypes } from "saur-states-management/lib/i18n";
import { States } from "saur-states-management";
import qs from "querystring";
import useRequestScript from "../common/useRequestScript";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import MatomoTags from 'saur-states-management/lib/i18n/matomo-tags';
import useEventListener from '../common/useEventListener'
import isAnalyticClientAgree from '../common/isAnalyticClientAgree'

import { colors } from 'saur-design-sys-web/lib/Theme';
import { settingsActions } from "saur-states-management/lib/states/settingsState";
import { ThemeType } from "saur-design-sys-web/lib/BrandsConfig";

// for testing on browser
// const exempleTokenNotValid = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVfYXQiOiIyMDIwLTA2LTAzVDEwOjMwOjMyLjQ0OVoifQ.yBIztqRk7qsv74Q-pI0vJJGCcUNy-l5h3yRN0mWc2-w"
// const exempleTokenNewValid = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVfYXQiOiIyMDMwLTA2LTAzVDEwOjMwOjMyLjQ0OVoifQ.Cj440bnNhsx2HY8Cqzk2zLuxmYMeoNtQ4EbGVpdCKy8"

const CreateAccountScreen = () => {
  useRequestScript(["move-in-script"]);
  const history = useHistory();
  const lang = useSelector(getLanguage) as langTypes;
  const { getPath } = usePath();
  const settingState = useSelector((state: States) => state.settings);
  const { trackEvent } = useMatomo()
  const _isAnalyticClientAgree = useSelector(isAnalyticClientAgree)
  const [moveInWidgetRef, setMoveInWidgetRef] = useState<Element | null>(null)
  const dispatch = useDispatch();


  useEffect(() => {
    const allowCookies = qs.parse(history.location.search)["?allowCookies"]?.toString();
    if (allowCookies === "accept-all") {
      dispatch(settingsActions.updateCookiesSetting([{
        id: 1,
        rule: "session",
        accepted: true,
      }, {
        id: 2,
        rule: "analytic",
        accepted: true,
      }]));
    } else if (allowCookies === "reject-all") {
      dispatch(settingsActions.updateCookiesSetting([{
        id: 1,
        rule: "session",
        accepted: false,
      }, {
        id: 2,
        rule: "analytic",
        accepted: false,
      }]));
    }
  }, [history.location, dispatch])

  const handlerMatomoEvent = useCallback(
    ({ detail }) => {
      let cclAccess = "";
      if (sessionStorage.getItem("sso-connection"))
        cclAccess = " [Accès CCL]"
      if (_isAnalyticClientAgree) {
        const { categorie, action, name } = detail
        trackEvent({
          category: categorie,
          action: action + cclAccess,
          name: name
        })
      }
    },
    [_isAnalyticClientAgree]
  );

  useEventListener(MatomoTags.direflowEventName, handlerMatomoEvent, moveInWidgetRef);

  useEventListener('go-to-log-in-page', (e) => {
    goToLogin();
  }, moveInWidgetRef);

  useEffect(() => {
    setMoveInWidgetRef(document.querySelector(
      "move-in-widget"
    ))
    if (window.scrollY > 0) {
      // reset scroll page
      window.scrollTo(0, 0);
    }

  }, []);

  function goToLogin() {
    history.push(`/${lang}${getPath("login")}`);
  }

  return (
    <>
      <Styles />
      <Container className="subscription-screen">
        <BubbleHeader wide goBack={goToLogin} title="Je m'abonne" />
        <Container className="subscription-page-background">
          <Container className="subscription-screen-content">
            <move-in-widget lang={lang} theme={settingState.theme} step="town-eligibility" />
          </Container>
        </Container>
        <img
          className="subscription-screen-background mobile"
          src="/assets/bgWave-payment-result-mobile.svg"
          alt="background-wave"
        />
        <img
          className="subscription-screen-background desktop"
          src="/assets/bgWave-payment-result-desktop.svg"
          alt="background-wave"
        />
      </Container>
    </>
  );
};

const Styles = createGlobalStyle`
  .sds-reset-style {
    .subscription-screen {
      .BubbleHeader .BubbleHeader-header .BubbleHeader-header-row  .BubbleHeader-BackButtonMobile{
        display:none;
      }
      .subscription-page-background{
        min-height:100vh;
        background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryLight};
      }
      .move-in-back-button{
        @media ${device.mobile} {
        position:absolute;
        top:0;
        }
        @media ${device.tablet} {
        position:initial;
        }
      }
      
      .error-card{
        margin-top:30px;
        svg{
          vertical-align: middle;
        }
        .error-message{
          color: ${colors.error};
          padding-left:10px;
          display: inline-block;
          vertical-align: middle;
        }
        .redirect-button{
          margin: 30px auto 15px auto;
        }
      }
    .subscription-screen-content {
      .move-in-widget .card-wrapper {
        @media ${device.tablet} {
        width:600px;
        margin:auto;
        }
      }
			flex: 1;
			@media ${device.mobile} {
				padding: 20px;
			}
			@media ${device.desktop} {
				padding: 0;
        width: 30%;
        min-width: 920px;
			}
      @media ${device.tablet} {
      margin: 200px auto;
      }
			margin-top: 10px;
			z-index:2;
		}
    .subscription-screen-background {
        position: fixed;
        bottom: 0;
        z-index: ${elevation.base.default};
        &.mobile {
          ${onlyForMobile}
        }
        &.desktop {
          ${onlyForDesktop}
        }
      }
    }
  }
`;

export default CreateAccountScreen;
